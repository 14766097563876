import { CloseTwoTone, DoneTwoTone } from "@mui/icons-material";
import { formatPriceWithCurrencySymbol } from "../../../helpers/getPriceWithCurrencySymbol";
import BookingGetters from "../../../store/onlineBooking/getters";
import { InsuranceCardProps } from "./Insurance.props";
import React, { useEffect, useRef, useState } from "react";
import AppButton from "../../UI/AppButtons";
import { t } from "i18next";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import { differenceInDays, differenceInHours, format } from "date-fns";
import { useTranslation } from "react-i18next";
import { TextResource } from "../../../@types/DTO/AppSetting";
import theme, { formatTheme } from "../../../config/MuiColorThemes";
import { createTheme, Theme, ThemeProvider } from "@mui/material/styles";

function InsuranceCardsLayout({
  insurances,
  onSelected,
  selectedInsuranceIndex = -1,
  secondaryColor = "",
  cardBackgroundColor = "",
  selectedCardBackgroundColor = "",
  cardTextColor = "",
  selectedCardTextColor = "",
  fillToCard,
  selectedGroup,
  insuranceBody,
}: InsuranceCardProps) {
  const isSmallScreen = window.innerWidth <= 768;
  const dynamicPalette = formatTheme();
  const dynamicTheme = createTheme(dynamicPalette);
  const allInsuranceFeatures: Record<string, boolean> = {};
  const allInsurances = insurances.map((el) => {
    const availableInsurances = el.features.reduce((prev, current) => {
      const feature = current.toLowerCase();
      prev[feature] = true;
      allInsuranceFeatures[feature] = true;
      return prev;
    }, {});
    return {
      bookingText: undefined,
      ...el,
      availableInsurances,
    };
  });
  const appSettings = BookingGetters.getAppSettings();
  const fontFamilyStyle = appSettings?.fontFamily;
  const defaultCurrency = appSettings?.defaultCurrency;
  const insuranceCardCollapsed = appSettings?.insuranceCardCollapsed ?? false; // Default to false it is only for felirent
  const textResource = BookingGetters.getTextResource()?.data ?? [];
  const [findOutMoreInsuranceLabel, setfindOutMoreInsuranceLabel] =
    useState<String>("");
  useEffect(() => {
    const data: TextResource[] = textResource ?? [];
    const findOutMore = data.find(
      (item) => item.key === "findOutMoreInsuranceLabel"
    );
    setfindOutMoreInsuranceLabel(
      findOutMore?.body || translate("Find out more")
    );
  }, [textResource]);
  const selectButtonInsuranceCard = textResource.some(
    (item) => item.key === "selectButtonInsuranceCard"
  )
    ? textResource.find((item) => item.key === "selectButtonInsuranceCard")
        ?.body
    : "";
  const removeButtonInsuranceCard = textResource.some(
    (item) => item.key === "removeButtonInsuranceCard"
  )
    ? textResource.find((item) => item.key === "removeButtonInsuranceCard")
        ?.body
    : "";
  const insuranceBackgroundColor =
    appSettings && appSettings["insuranceBackgroundColor"];
  const scrollToTop = () => {
    window.parent.postMessage(
      {
        event_id: "scrollToTop",
      },
      "*"
    );
  };
  const currencyDetails = BookingGetters.getCurrency();
  const boxShadow = "shadow-lg";
  const [expandedIndex, setExpandedIndex] = useState(-1);
  const handleCardClick = (index: number) => {
    if (expandedIndex == index) {
      setExpandedIndex(-1);
      onSelected(-1);
    } else {
      setExpandedIndex(index);
      onSelected(index);
    }
  };

  const [expandedCardIndex, setExpandedCardIndex] = useState<number[]>([]);
  const showInsuranceDetails = (value: number, insurance) => {
    setExpandedCardIndex((prevExpandedCardIndex) => {
      const index = prevExpandedCardIndex.indexOf(value);
      if (index !== -1) {
        return prevExpandedCardIndex.filter((item) => item !== value);
      } else {
        return [...prevExpandedCardIndex, value];
      }
    });
  };
  const ReservationDetails = BookingGetters.getBookingDetails();
  const [startDate] = useState(
    new Date(ReservationDetails.pickUpDateAndTime || Date.now())
  );

  const [endDate] = useState(
    new Date(ReservationDetails.dropOffDateAndTime || Date.now())
  );
  const { t: translate } = useTranslation();
  const [numberOfDays] = useState(differenceInDays(endDate, startDate));
  const [differenceInHour] = useState(differenceInHours(endDate, startDate));
  const approximateDays = Math.ceil(differenceInHour / 24);
  return (
    <>
      <h2
        className="text-black text-xl font-semibold mb-2"
        style={{ gridColumn: "1 / -1" }}
      >
        {t("Add coverage")}
      </h2>
      <div
        className="flex flex-wrap justify-center  text-black rounded-md"
        style={{
          background: insuranceBackgroundColor || "#ffffff",
          alignItems: expandedCardIndex.length === 0 ? "stretch" : "center",
        }}
      >
        {allInsurances
          .filter((el) => {
            return appSettings?.showInsurancePreview
              ? el.pricePerDayInUSD !== "0"
              : true;
          })
          .map((el, index) => (
            <div
              className={`${boxShadow} m-8 p-8 cursor-pointer transition-transform duration-300 flex-1 flex flex-col justify-center items-center 
              hover:-translate-y-2 rounded-3xl bg-white`}
              key={index}
              style={{
                flexBasis: "290px",
                maxWidth: "290px",
                minWidth: "290px",
                flexGrow: 0,
              }}
            >
              {insuranceCardCollapsed && !expandedCardIndex.includes(index) && (
                <span className="font-medium">{findOutMoreInsuranceLabel}</span>
              )}
              <button
                id={"showBookText" + index}
                onClick={() => {
                  showInsuranceDetails(index, el.name);
                }}
              >
                {expandedCardIndex.includes(index) ? (
                  <ExpandLess />
                ) : (
                  <ExpandMore />
                )}
              </button>
              <h5
                className="text-xl font-semibold text-center mb-2"
                style={{ color: secondaryColor }}
              >
                {el.name}
              </h5>
              <p
                className="text-lg mb-2 font-bold"
                style={{ color: selectedCardBackgroundColor }}
              >
                {defaultCurrency ||
                  formatPriceWithCurrencySymbol(currencyDetails).symbol + " "}
                {"  "}
                {el.pricePerDayInUSD}
              </p>
              {insuranceCardCollapsed &&
                el.features.map((feature, index1) => (
                  <div
                    key={index1}
                    className="text-md"
                    style={{
                      textAlign: "center",
                      fontWeight: "500",
                      fontSize: "small",
                    }}
                  >
                    {!expandedCardIndex.includes(index) && (
                      <>
                        <DoneTwoTone className="text-green-800 font-bold mr-2" />
                        <span>{feature}</span>
                      </>
                    )}
                  </div>
                ))}
              <div
                className="text-center"
                style={
                  insuranceCardCollapsed
                    ? expandedCardIndex.includes(index)
                      ? { display: "block" }
                      : { display: "none" }
                    : expandedCardIndex.includes(index)
                    ? isSmallScreen
                      ? { display: "block" }
                      : { display: "none" }
                    : isSmallScreen
                    ? { display: "none" }
                    : { display: "block" }
                }
              >
                {insuranceBody && insuranceBody[el.name] ? (
                  <div
                    style={{ whiteSpace: "pre-line", fontSize: "small" }}
                    dangerouslySetInnerHTML={{
                      __html: insuranceBody[el.name],
                    }}
                  />
                ) : (
                  Object.keys(allInsuranceFeatures).map((feature, i) => (
                    <div key={i} className="items-center mb-2">
                      <div className="flex">
                        {feature.trim() !== "" &&
                          (el.availableInsurances[feature] ? (
                            <DoneTwoTone className="text-green-800 font-bold mr-2" />
                          ) : (
                            <CloseTwoTone className="text-red-800 mr-2" />
                          ))}

                        <div
                          className="text-md"
                          style={{ textAlign: "left", fontWeight: "500" }}
                        >
                          {feature}
                        </div>
                      </div>
                      {el.bookingText &&
                        el.bookingText[
                          feature.toUpperCase() + ":" + el.name.toUpperCase()
                        ] && (
                          <div>
                            <p
                              style={{
                                textAlign: "left",
                                fontSize: "small",
                                marginLeft: "15%",
                              }}
                            >
                              <div
                                style={{
                                  whiteSpace: "pre-line",
                                  fontSize: "small",
                                }}
                                dangerouslySetInnerHTML={{
                                  __html:
                                    el.bookingText[
                                      feature.toUpperCase() +
                                        ":" +
                                        el.name.toUpperCase()
                                    ],
                                }}
                              />
                            </p>
                          </div>
                        )}
                    </div>
                  ))
                )}
              </div>

              <AppButton
                className={`h-14 w-31 ${
                  el?.pricePerDayInUSD === "0"
                    ? "cursor-not-allowed"
                    : "cursor-pointer"
                }`}
                color={
                  selectedInsuranceIndex === index
                    ? "primary"
                    : "secondaryColor"
                }
                onClick={() => {
                  handleCardClick(index);
                  scrollToTop();
                }}
                style={{
                  borderRadius: "5px",
                  marginTop: "9px",
                  background:
                    selectedInsuranceIndex === index
                      ? dynamicTheme?.palette["secondaryColor"]?.main
                      : dynamicTheme?.palette["primary"].main,
                }}
                disabled={el.pricePerDayInUSD === "0"}
              >
                <p
                  className="w-full text-center"
                  style={{
                    fontFamily: fontFamilyStyle,
                    color: "white",
                  }}
                >
                  {el.pricePerDayInUSD === "0" ? (
                    <span>{t("INCLUDED")}</span>
                  ) : selectedInsuranceIndex != index ? (
                    selectButtonInsuranceCard ? (
                      <span
                        dangerouslySetInnerHTML={{
                          __html: selectButtonInsuranceCard,
                        }}
                      />
                    ) : (
                      <span>{translate("Select")}</span>
                    )
                  ) : removeButtonInsuranceCard ? (
                    <span
                      dangerouslySetInnerHTML={{
                        __html: removeButtonInsuranceCard,
                      }}
                    />
                  ) : (
                    <span>{translate("Remove")}</span>
                  )}
                </p>
              </AppButton>
            </div>
          ))}
      </div>
    </>
  );
}
export { InsuranceCardsLayout };
