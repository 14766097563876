import React, { useEffect, useState } from "react";
import { BOOKING_ACTIONS } from "../../../store/onlineBooking";
import {
  AccountBalance,
  AccountBalanceWallet,
  RadioButtonCheckedOutlined,
  RadioButtonUncheckedOutlined,
} from "@material-ui/icons";
import { useDispatch } from "react-redux";
import BookingGetters from "../../../store/onlineBooking/getters";
import { formatTheme } from "../../../config/MuiColorThemes";
import { createTheme } from "@mui/material/styles";
import { ChosenOptions } from "../BookingVehicleInfoCard/BookingVehicleInfoCard.props";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import { TextResource } from "../../../@types/DTO/AppSetting";
function PaymentTypesDetails({
  reservationSourcePayNow,
  reservationSourcePayLater,
  extrasPrice,
  updateVehiclePrice,
  updateSelectedPrice,
}) {
  const [selectedBox, setSelectedBox] = useState<"payNow" | "payLater" | null>(
    "payNow"
  );
  const [reservationPayNow] = useState<string>(reservationSourcePayNow || "");
  const [reservationPayLater] = useState<string>(
    reservationSourcePayLater || ""
  );
  const [userId] = useState(localStorage.getItem("userID"));
  const dispatch = useDispatch();
  const dynamicPalette = formatTheme();
  const dynamicTheme = createTheme(dynamicPalette);
  const isSmallScreen = window.innerWidth <= 768;
  const backgroundpaymentTextColor =
    dynamicTheme?.palette["backgroundColor"].contrastText;
  const selectedVehicle =
    BookingGetters.getSelectedVehicleDetails()?.vehicleDetails;
  const discount =
    reservationSourcePayLater === "true" ||
    (userId != null && userId != "null" && userId != "")
      ? 0
      : (100 - (selectedVehicle?.Vehicle?.payNowDis || 0)) / 100;
  const [totalPrice] = useState<number>(
    reservationSourcePayLater === "true" ||
      (userId != null && userId != "null" && userId != "")
      ? parseFloat(
          (selectedVehicle?.Reference?.ID && selectedVehicle?.Reference?.ID > 0
            ? (
                selectedVehicle?.Reference.originalAmount -
                selectedVehicle?.Reference.ID
              ).toString()
            : selectedVehicle?.TotalCharge?.RateTotalAmount.toString()) || "0"
        )
      : parseFloat(
          (selectedVehicle?.Reference?.ID && selectedVehicle?.Reference?.ID > 0
            ? (
                selectedVehicle?.Reference?.originalAmount -
                selectedVehicle?.Reference.ID
              ).toString()
            : selectedVehicle?.TotalCharge?.RateTotalAmount.toString()) || "0"
        ) * discount
  );
  const [selectedFields] = useState<ChosenOptions>({
    options: {},
    insurance: undefined,
  });
  const [payLaterPrice] = useState<number>(
    parseFloat(
      (selectedVehicle?.Reference?.ID && selectedVehicle?.Reference?.ID > 0
        ? (
            selectedVehicle?.Reference?.originalAmount -
            selectedVehicle?.Reference.ID
          ).toString()
        : selectedVehicle?.TotalCharge?.RateTotalAmount.toString()) || "0"
    ) || 0
  );
  const [payNowDiscount, setPayNowDiscount] = useState("0");
  useEffect(() => {
    if (reservationPayNow == "true") {
      selectPayNow();
    }
  }, []);

  const textResource = BookingGetters.getTextResource()?.data ?? [];
  const [payNowRadioButton, setPayNow] = useState<String>("");
  const [payLaterRadioButton, setPaylater] = useState<String>("");
  const { t: translate } = useTranslation();
  useEffect(() => {
    const data: TextResource[] = textResource ?? [];
    const payNowData = data.find((item) => item.key === "payNowRadioButton");
    const payLaterData = data.find(
      (item) => item.key === "payLaterRadioButton"
    );
    setPayNow(payNowData?.body || translate("Pay Now"));
    setPaylater(payLaterData?.body || translate("Pay Later"));
  }, [textResource]);

  const selectPayNow = () => {
    setSelectedBox("payNow");

    if (selectedVehicle) {
      dispatch(
        BOOKING_ACTIONS.setSelectedVehicleDetails({
          paymentDetails: {
            addedExtraOptions: selectedFields.options,
            isDiscounted:
              (parseFloat(
                selectedVehicle?.Reference?.ID &&
                  selectedVehicle?.Reference?.ID > 0
                  ? (
                      selectedVehicle?.Reference?.originalAmount -
                      selectedVehicle?.Reference.ID
                    ).toString()
                  : selectedVehicle?.TotalCharge?.RateTotalAmount.toString() ||
                      "0"
              ) *
                parseFloat(selectedVehicle?.Vehicle?.payNowDis)) /
                100 || 0,
            price: totalPrice,
            selectedInsurance: selectedFields.insurance,
            type: "PAYNOW",
            isPayLaterFee: false,
            payLaterFeeAmt: "",
            extrasPrice: 0,
            extrasIncluded: false,
          },
          vehicleDetails: selectedVehicle,
        })
      );
      setTimeout(() => {}, 200);
    }
  };
  useEffect(() => {
    if (reservationPayLater == "true") {
      selectPayLater();
    }
  }, []);
  const selectPayLater = () => {
    setSelectedBox("payLater");

    if (selectedVehicle) {
      dispatch(
        BOOKING_ACTIONS.setSelectedVehicleDetails({
          paymentDetails: {
            addedExtraOptions: selectedFields?.options,
            isDiscounted: 0,
            price: payLaterPrice,
            selectedInsurance: selectedFields?.insurance,
            type: "PAYLATER",
            isPayLaterFee: false,
            payLaterFeeAmt: "",
            extrasPrice: 0,
            extrasIncluded: false,
          },
          vehicleDetails: selectedVehicle,
        })
      );
      setTimeout(() => {}, 200);
    }
  };

  useEffect(() => {
    if (payLaterPrice > 0) {
      const dis: number = ((payLaterPrice - totalPrice) / payLaterPrice) * 100;
      setPayNowDiscount(dis?.toFixed(0));
    }
  }, [totalPrice, payLaterPrice]);

  const [isDesktop] = useState(window.innerWidth >= 1300);
  useEffect(() => {
    if (selectedBox == "payNow") {
      updateVehiclePrice(totalPrice);
      updateSelectedPrice(
        (totalPrice + parseFloat(String(extrasPrice))).toFixed(2)
      );
    } else if (selectedBox == "payLater") {
      updateVehiclePrice(payLaterPrice);
      updateSelectedPrice(
        (payLaterPrice + parseFloat(String(extrasPrice))).toFixed(2)
      );
    }
  }, [selectedBox]);
  return (
    <div
      className={`flex ${
        isDesktop ? "flex-row" : "flex-col md:flex-row"
      } w-full h-full relative`}
    >
      <div
        className={`flex ${
          isDesktop
            ? "px-4 grow max-w-[30%]"
            : "justify-center px-4 py-4 mb-4 md:mb-0 md:mr-4"
        } text-gray-500 text-sm relative ${
          selectedBox == "payNow" ? "custom-shadow" : ""
        } border rounded-md`}
        style={
          reservationPayNow == "true" || reservationPayLater == "true"
            ? { pointerEvents: "none", opacity: 0.6 }
            : {}
        }
      >
        <div
          className={`flex-1 p-4 cursor-pointer`}
          onClick={() => {
            if (reservationPayNow === "false") {
              selectPayNow();
            }
          }}
        >
          <div className="flex items-center justify-center">
            <img
              src="/assets/icons/PayNowIcon.png"
              style={{ height: "48px", width: "48px" }}
            />
            <div>
              <h3
                className={`text-center text-xl font-bold`}
                style={{
                  marginLeft: "20px",
                  color: backgroundpaymentTextColor,
                }}
              >
                {payNowRadioButton}
              </h3>
              {payNowDiscount != "0" && (
                <h3
                  className={`text-center`}
                  style={{
                    marginLeft: "3px",
                    color: backgroundpaymentTextColor,
                  }}
                >
                  {t("Discount")} {payNowDiscount}%
                </h3>
              )}
            </div>
          </div>
          <div
            className="flex items-center justify-between"
            style={{
              marginLeft: isDesktop ? "27%" : "5%",
              marginTop: "20px",
              justifyContent: "space-between",
            }}
          >
            <p
              className={`text-center text-3xl`}
              style={{
                color: backgroundpaymentTextColor,
                fontSize: isSmallScreen ? "1.3rem" : "1.875rem",
              }}
            >
              €{(totalPrice + parseFloat(String(extrasPrice))).toFixed(2)}
            </p>
            {selectedBox === "payNow" ? (
              <RadioButtonCheckedOutlined
                style={{
                  color: dynamicTheme?.palette["primary"]?.main,
                  fontSize: "xx-large",
                }}
              />
            ) : (
              <RadioButtonUncheckedOutlined
                style={{
                  color: dynamicTheme?.palette["secondaryColor"]?.main,
                  fontSize: "xx-large",
                }}
              />
            )}
          </div>
        </div>
      </div>
      <div
        className={`flex ${
          isDesktop
            ? "px-4 grow max-w-[30%] md:ml-4"
            : "justify-center px-4 py-4 mb-4 md:mb-0 md:mr-4"
        } text-gray-500 text-sm relative ${
          selectedBox == "payLater" ? "custom-shadow" : ""
        } border rounded-md `}
        style={
          reservationPayNow == "true" || reservationPayLater == "true"
            ? { pointerEvents: "none", opacity: 0.6 }
            : {}
        }
      >
        <div
          className={`flex-1 p-4 cursor-pointer`}
          onClick={() => {
            if (reservationPayLater === "false") {
              selectPayLater();
            }
          }}
        >
          <div className="flex items-center justify-center">
            <img
              src="/assets/icons/PayAtDeskIcon.png"
              style={{ height: "48px", width: "48px" }}
            />
            <h3
              className={`text-center text-xl font-bold`}
              style={{
                marginLeft: "20px",
                color: backgroundpaymentTextColor,
              }}
            >
              {payLaterRadioButton}
            </h3>
          </div>
          <div
            className="flex items-center justify-between"
            style={{
              marginTop: "20px",
              marginLeft: isDesktop ? "25%" : "5%",
              justifyContent: "space-between",
            }}
          >
            <p
              className={`text-center text-3xl`}
              style={{
                color: backgroundpaymentTextColor,
                fontSize: isSmallScreen ? "1.3rem" : "1.875rem",
              }}
            >
              €{(payLaterPrice + parseFloat(String(extrasPrice)))?.toFixed(2)}
            </p>
            {selectedBox === "payLater" ? (
              <RadioButtonCheckedOutlined
                style={{
                  color: dynamicTheme?.palette["primary"]?.main,
                  fontSize: "xx-large",
                }}
              />
            ) : (
              <RadioButtonUncheckedOutlined
                style={{
                  color: dynamicTheme?.palette["secondaryColor"]?.main,
                  fontSize: "xx-large",
                }}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export { PaymentTypesDetails };
