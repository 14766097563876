import { BookingStateRedux } from "./states";
import i18n from "../../config/i18n";
import { useSelector } from "react-redux";

function getBookingState(state, key: string) {
  const value = state.booking[key];
  return value;
}

const BookingGetters = {
  getIsEmbedded() {
    return useSelector((state) => getBookingState(state, "isEmbedded"));
  },
  getBookingOptions() {
    return useSelector(
      (state) => getBookingState(state, "homePageConfig") || {}
    );
  },
  getIsLoggedIn() {
    return useSelector((state) => !!getBookingState(state, "isLoggedIn"));
  },
  getCurrency() {
    return useSelector((state) => getBookingState(state, "currency"));
  },
  getBookingDetails(): BookingStateRedux["bookingInformation"] {
    return useSelector(
      (state) => getBookingState(state, "bookingInformation") || {}
    );
  },
  getRentalTerms() {
    return useSelector((state) => getBookingState(state, "rentalTerms"));
  },
  getAllAvailableLanguages() {
    return i18n.languages;
  },
  getSelectedLanguage() {
    return useSelector((state) => getBookingState(state, "selectedLanguage"));
  },
  getSessionExpiryTime() {
    return useSelector((state: BookingStateRedux) =>
      getBookingState(state, "sessionExpiryTime")
    );
  },
  getSelectedVehicleDetails(): BookingStateRedux["selectedVehicleDetails"] {
    return useSelector((state: BookingStateRedux) =>
      getBookingState(state, "selectedVehicleDetails")
    );
  },
  getUserDetails(): BookingStateRedux["userDetails"] {
    return useSelector((state: BookingStateRedux) =>
      getBookingState(state, "userDetails")
    );
  },
  getAppSettings(): BookingStateRedux["appSetting"] {
    return useSelector((state: BookingStateRedux) =>
      getBookingState(state, "appSetting")
    );
  },
  getTextResource(): BookingStateRedux["textresource"] {
    return useSelector((state: BookingStateRedux) =>
      getBookingState(state, "textresource")
    );
  },
  getCustomerPrimaryInfo(): BookingStateRedux["customerPrimaryDetails"] {
    return useSelector((state: BookingStateRedux) =>
      getBookingState(state, "customerPrimaryDetails")
    );
  },
  getAffiliateLocationDetails(): BookingStateRedux["affiliateLocationDetails"] {
    return useSelector((state: BookingStateRedux) =>
      getBookingState(state, "affiliateLocationDetails")
    );
  },
};
export { BookingGetters as default, BookingGetters };
