import {
  CalendarPickerView,
  MobileDatePicker as MuiDatePicker,
  DateTimePicker,
} from "@mui/x-date-pickers";
import {
  DateAndTimePickerProps,
  DatePickerSeparateProps,
  DateRangePickerProps,
  StandardDatePickerProps,
} from "./AppPickers.props";
import React, { ForwardedRef, forwardRef, useEffect, useState } from "react";
import dayjs, { Dayjs } from "dayjs";
import { InputLabel } from "@mui/material";
import { KeyboardArrowDown } from "@mui/icons-material";
import RangePicker from "react-datepicker";
import TextField from "@mui/material/TextField";
import { getDay } from "date-fns";
import { useDatePickerStyles } from "./AppPicker.styles";
import "./Calendar.css";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import { enUS, it } from "date-fns/locale";
import i18n from "../../../config/i18n";
import { makeStyles } from "@mui/styles";
import { BookingDetails } from "../../../@types/Booking/bookingDetails";
import BookingGetters from "../../../store/onlineBooking/getters";
registerLocale("it", it);
registerLocale("enUS", enUS);
export { AppDatePicker as default };
export const AppDatePicker = forwardRef(function AppDatePicker(
  {
    value,
    label,
    className,
    onChange = () => {},
    minDate: initialMinDate,
    maxDate: initialMaxDate,
    showTodayButton = true,
    readOnly = false,
    required = true,
    inputFormat = "dd/MM/yyyy",
    dateError,
  }: StandardDatePickerProps,
  ref: ForwardedRef<HTMLInputElement>
) {
  const [tempValue, setTempValue] = useState<string | null>(
    value?.toString() || null
  );
  const [language, setLanguage] = React.useState("it");
  React.useEffect(() => {
    const selectedLanguage = i18n.language;
    setLanguage(selectedLanguage);
  }, []);
  const reduxBookingDetails: BookingDetails =
    BookingGetters.getBookingDetails();
  const [minDate, setMinDate] = useState<Date | undefined>(initialMinDate);
  const [maxDate, setMaxDate] = useState<Date | undefined>(initialMaxDate);
  const [keyboardDateError, setDateError] = useState("");
  useEffect(() => {
    if (reduxBookingDetails.isYoungDriverAge == "true") {
      setMinAndMaxAgeValue(reduxBookingDetails.youngDriverAgeValue);
    } else if (reduxBookingDetails.isYoungDriver2Age == "true") {
      setMinAndMaxAgeValue(reduxBookingDetails.youngDriver2AgeValue);
    } else if (reduxBookingDetails.isSeniorDriverAge == "true") {
      setMinAndMaxAgeValue(reduxBookingDetails.seniorDriverAgeValue);
    } else if (reduxBookingDetails.noFeeAge == "true") {
      setMinAndMaxAgeValue(reduxBookingDetails.noAgeDriverValue);
    }
  }, [reduxBookingDetails]);

  function setMinAndMaxAgeValue(driverAge) {
    let tempMinAge;
    let tempMaxAge;
    if (/between/i.test(driverAge)) {
      // Handles cases like "age between 19-21" or "age between 19 to 21"
      const ageMatch = driverAge.match(/(\d+)\D+(\d+)/);
      if (ageMatch) {
        tempMinAge = parseInt(ageMatch[1], 10);
        tempMaxAge = parseInt(ageMatch[2], 10);
      }
    } else if (/\d+\+/.test(driverAge)) {
      // Handles cases like "age 25+"
      const ageMatch = driverAge.match(/(\d+)\+/);
      if (ageMatch) {
        tempMinAge = parseInt(ageMatch[1], 10);
        tempMaxAge = 100;
      }
    } else if (/(\d+)\D+(\d+)/.test(driverAge)) {
      // Handles cases like "19-21" or "19 to 21"
      const ageMatch = driverAge.match(/(\d+)\D+(\d+)/);
      if (ageMatch) {
        tempMinAge = parseInt(ageMatch[1], 10);
        tempMaxAge = parseInt(ageMatch[2], 10);
      }
    } else {
      // Handles single age case, e.g., "age 25"
      const ageMatch = driverAge.match(/(\d+)/);
      if (ageMatch) {
        tempMinAge = parseInt(ageMatch[1], 10);
        tempMaxAge = null; // No upper limit for age
      }
    }
    calculateDateRange(tempMinAge, tempMaxAge);
  }

  const calculateDateRange = (minAge, maxAge) => {
    const currentYear = new Date().getFullYear();
    setMinDate(new Date(currentYear - maxAge, 0, 1));
    setMaxDate(new Date(currentYear - minAge, 11, 31));
  };
  const appSettings = BookingGetters.getAppSettings();
  const fontFamilyStyle = appSettings?.fontFamily;
  return (
    <>
      <div
        className={`w-full ${className}`}
        style={{ position: "relative", zIndex: 1000 }}
      >
        {language == "it" ? (
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={it}>
            <MuiDatePicker
              inputRef={ref}
              label={
                <span style={{ fontFamily: fontFamilyStyle }}>{label}</span>
              }
              inputFormat={inputFormat}
              value={(tempValue as string) || null}
              onChange={(e: Date | null, keyBoardValue: string | undefined) => {
                if (keyBoardValue) {
                  if (/\d+\d+\/\d+\d+\/\d+\d+\d+\d+/.test(keyBoardValue)) {
                    const [D, M, Y] = keyBoardValue.split("/");
                    setTempValue(M + "/" + D + "/" + Y);
                    const year = parseInt(Y, 10);
                    const minYear = minDate ? minDate.getFullYear() : null;
                    const maxYear = maxDate ? maxDate.getFullYear() : null;

                    if (
                      (minYear && year < minYear) ||
                      (maxYear && year > maxYear)
                    ) {
                      setDateError(
                        `Please enter a date between ${minYear} and ${maxYear}`
                      );
                      return;
                    }
                    setTempValue(M + "/" + D + "/" + Y);
                  } else {
                    setDateError("");
                  }
                  return;
                }
                setTempValue(e?.toString() || null);
              }}
              onAccept={(e) => {
                onChange(e?.toString() || null);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  required={required}
                  helperText={
                    dateError || keyboardDateError
                      ? dateError || keyboardDateError
                      : ""
                  }
                  sx={{
                    "& .MuiInputBase-input": {
                      fontFamily: fontFamilyStyle,
                    },
                  }}
                />
              )}
              openTo={maxDate || minDate ? "year" : "day"}
              minDate={minDate}
              maxDate={maxDate}
              disableHighlightToday={!showTodayButton}
              disabled={readOnly}
            />
          </LocalizationProvider>
        ) : (
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={enUS}>
            <MuiDatePicker
              inputRef={ref}
              label={
                <span style={{ fontFamily: fontFamilyStyle }}>{label}</span>
              }
              inputFormat={inputFormat}
              value={(tempValue as string) || null}
              onChange={(e: Date | null, keyBoardValue: string | undefined) => {
                if (keyBoardValue) {
                  if (/\d+\d+\/\d+\d+\/\d+\d+\d+\d+/.test(keyBoardValue)) {
                    const [D, M, Y] = keyBoardValue.split("/");
                    setTempValue(M + "/" + D + "/" + Y);
                    const year = parseInt(Y, 10);
                    const minYear = minDate ? minDate.getFullYear() : null;
                    const maxYear = maxDate ? maxDate.getFullYear() : null;

                    if (
                      (minYear && year < minYear) ||
                      (maxYear && year > maxYear)
                    ) {
                      setDateError(
                        `Please enter a date between ${minYear} and ${maxYear}`
                      );
                      return;
                    }
                    setTempValue(M + "/" + D + "/" + Y);
                  } else {
                    setDateError("");
                  }
                  return;
                }
                setTempValue(e?.toString() || null);
              }}
              onAccept={(e) => {
                onChange(e?.toString() || null);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  required={required}
                  helperText={
                    dateError || keyboardDateError
                      ? dateError || keyboardDateError
                      : ""
                  }
                />
              )}
              openTo={maxDate || minDate ? "year" : "day"}
              minDate={minDate}
              maxDate={maxDate}
              disableHighlightToday={!showTodayButton}
              disabled={readOnly}
            />
          </LocalizationProvider>
        )}
      </div>
    </>
  );
});

export const DateRangePicker = forwardRef(DateRangePickerComponent);
export function DateRangePickerComponent(
  {
    startDate = null,
    endDate = null,
    required = false,
    className = "",
    label = "",
    disableOnDay = [],
    onChange = () => {},
    minDate = new Date(),
    color = "",
    ...props
  }: DateRangePickerProps,
  ref: ForwardedRef<HTMLInputElement | null>
) {
  const [start, setStart] = useState(startDate);
  const [end, setEnd] = useState(endDate);
  const appSettings = BookingGetters.getAppSettings();
  const fontFamilyStyle = appSettings?.fontFamily;
  const isDesktopScreen = window.innerWidth >= 1310;

  // Passing color dynamically to the CSS file
  document.documentElement.style.setProperty("--selected-color", color);

  const handleDateChange = (dates) => {
    const [newStartDate, newEndDate] = dates;

    setStart(newStartDate);
    setEnd(newEndDate);

    onChange(dates);
  };

  return (
    <div
      className={`w-full ${className} `}
      style={{ zIndex: isDesktopScreen ? "10" : "inherit" }}
    >
      <RangePicker
        locale={i18n.language}
        className={"w-full h-full"}
        dateFormat="dd MMM,yyyy"
        isClearable
        required={required}
        monthsShown={2}
        minDate={minDate}
        maxDate={props.maxDate}
        selected={start}
        selectsRange
        startDate={start}
        endDate={end}
        withPortal={!isDesktopScreen}
        autoComplete="off"
        customInput={
          <TextField
            autoComplete={Date.now().toString()}
            label={<span style={{ fontFamily: fontFamilyStyle }}>{label}</span>}
            sx={{
              "& .MuiInputBase-input": {
                fontFamily: fontFamilyStyle,
              },
            }}
            required={required}
            fullWidth
            inputRef={ref}
            inputProps={{ readOnly: true }} // Prevent keyboard from opening
          />
        }
        filterDate={(e) => {
          const isEnabled = disableOnDay.indexOf(getDay(e)) > -1;
          return !isEnabled;
        }}
        onChange={handleDateChange}
      />
    </div>
  );
}

export function AppDateAndTimePicker({
  label,
  value,
  showTodayButton,
  minDateTime,
  onAccept,
  className = "",
  disableOnDay = [],
  required = false,
  readOnly = false,
}: DateAndTimePickerProps) {
  return (
    <div className={`w-full min-w-50 ${className}`}>
      <DateTimePicker
        disabled={readOnly}
        label={label}
        value={value}
        onChange={(e) => {
          console.log(e);
        }}
        renderInput={(params) => (
          <TextField {...params} fullWidth required={required} />
        )}
        disableHighlightToday={!showTodayButton}
        minDateTime={minDateTime}
        onAccept={onAccept}
        shouldDisableDate={(date: Date) => {
          return disableOnDay.includes(date.getDay());
        }}
        ampm={true}
      />
    </div>
  );
}

export const AppDatePickerSeparate = forwardRef(
  function AppDatePickerSeparateComponent(
    {
      onChange = () => {},
      label,
      disableOnDay = [],
      onAccept = () => {},
      onError = () => {},
      dayLabel = "Giorno",
      monthLabel = "Mese",
      yearLabel = "Anno",
      className = "",
      value,
      dateError,
      readOnly = false,
    }: DatePickerSeparateProps,
    ref: ForwardedRef<HTMLInputElement>
  ) {
    interface DatePickerFormat {
      label: string;
      views: CalendarPickerView[];
      inputFormat?: string;
      mask?: string;
      onChange?: () => void;
      disabled?: boolean;
    }

    const DatePickerStyle = useDatePickerStyles();
    const datePickers: Array<DatePickerFormat> = [];
    datePickers.push({
      label: dayLabel,
      views: ["day"],
      inputFormat: "dd",
      disabled: readOnly,
    });
    datePickers.push({
      label: monthLabel,
      views: ["month"],
      inputFormat: "MMM",
      disabled: readOnly,
    });
    datePickers.push({
      label: yearLabel,
      views: ["year"],
      inputFormat: "yyyy",
      disabled: readOnly,
    });
    return (
      <div className={`w-full h-full border-box flex flex-col  ${className}`}>
        <div className="h-8">
          <InputLabel>{label}</InputLabel>
        </div>

        <div
          className={`py-2 box-border items-center justify-center h-full flex flex-row gap-2 w-full ${DatePickerStyle.dateSeparated}`}
        >
          {datePickers.map((el: DatePickerFormat, index: number) => {
            return (
              <div className={"shrink h-full"} key={index}>
                <AppDatePicker
                  locale="it"
                  {...el}
                  dateError={dateError}
                  value={value}
                  inputRef={ref}
                  components={{
                    OpenPickerIcon: KeyboardArrowDown,
                  }}
                  onChange={(date: Date | null) => {
                    // if (!date) {
                    //   return onChange(null);
                    // }
                    onChange(date?.toString() || null);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      inputProps={{ readOnly: true }} // Prevent keyboard from opening
                      disabled={false}
                    />
                  )}
                  onAccept={(date: Date | null) => {
                    onChange(date?.toString() || null);
                  }}
                  onError={onError}
                  inputFormat="dd/MM/yyyy"
                  shouldDisableDate={(date: Date) => {
                    return disableOnDay.includes(date.getDay());
                  }}
                />
              </div>
            );
          })}
        </div>
      </div>
    );
  }
);
