import { formatPriceWithCurrencySymbol } from "../../../helpers/getPriceWithCurrencySymbol";
import BookingGetters from "../../../store/onlineBooking/getters";
import React, { useState, useEffect } from "react";
import theme from "../../../config/MuiColorThemes";
import { Button, Card } from "@mui/material";
import { TextResource } from "../../../@types/DTO/AppSetting";
import { useTranslation } from "react-i18next";
import AppButton from "../AppButtons";
import { CheckCircleOutlined } from "@mui/icons-material";

function VehicleExtrasWithInfo({
  iconUrl,
  optionName,
  pricePerDayInUSD,
  details,
  onDecrementClick,
  onIncrementClick,
  value,
  cardBackgroundColor,
  selectedCardBackgroundColor,
  cardTextColor,
  selectedCardTextColor,
  secondaryColor,
  fillToCard,
  maxAllowed,
  showOptionalCode,
  showOptionalDescription,
  showDescFromBookingText,
  isDailyPrice,
}): JSX.Element {
  const buttonBorderStyles = "border-solid border-black-300 border-2 ";
  const isDesktopScreen = window.innerWidth >= 1310;
  const appSettings = BookingGetters.getAppSettings();
  const fontFamilyStyle = appSettings?.fontFamily;
  const textResource = BookingGetters.getTextResource();
  const data: TextResource[] = textResource?.data ?? [];
  const optionalObject = data.find((item) => item.key === optionName);
  const optionalBody = optionalObject ? optionalObject?.body : "";
  const { t: translate } = useTranslation();
  const [showInfo, setShowInfo] = useState(false);
  const toogleVehicleInfoCardShowingStatus = () => {
    setShowInfo(!showInfo);
  };
  const defaultCurrency = appSettings?.defaultCurrency;
  const marginTopStyle =
    showDescFromBookingText && optionalBody && showOptionalDescription
      ? "2px"
      : "12px";
  const marginTopStyle1 =
    showDescFromBookingText && optionalBody && showOptionalDescription
      ? "10px"
      : "25px";
  const isSmallScreen = window.innerWidth <= 768;
  const textResourceInfo = BookingGetters.getTextResource()?.data ?? [];
  const extrasSelectButtonLabel = textResourceInfo.some(
    (item) => item.key === "selectVehicleExtrasLabel"
  )
    ? textResourceInfo.find((item) => item.key === "selectVehicleExtrasLabel")
        ?.body
    : "";
  const extrasRemoveButtonLabel = textResourceInfo.some(
    (item) => item.key === "removeVehicleExtrasLabel"
  )
    ? textResourceInfo.find((item) => item.key === "removeVehicleExtrasLabel")
        ?.body
    : "";
  return (
    <>
      {maxAllowed && maxAllowed > 1 ? (
        <Card
          sx={{
            maxWidth: 550,
            borderRadius: theme.shape.borderRadius,
            boxShadow:
              "0 4px 6px rgba(0, 0, 0, 0.1), 0 2px 6px rgba(0, 0, 0, 0.1)",
            margin: "9px",
            height: "130px",
          }}
        >
          <div
            className="flex flex-row items-center text-sm"
            style={{
              marginLeft: "6px",
              height: "55px",
              marginTop: "9px",
            }}
          >
            {iconUrl != "#" && appSettings?.showHideExtrasImages && (
              <img
                src={iconUrl}
                className="w-full aspect-square"
                style={{ width: "10%" }}
                alt={""}
              ></img>
            )}
            <main className="flex flex-col box-border grow max-w-[75%] grow text-sm relative mt-2 mb-2">
              {appSettings?.showOptionalCodeFromTextRes ? (
                <span
                  className="font-bold break-words"
                  style={
                    value > 0
                      ? { color: secondaryColor, padding: "2%" }
                      : { padding: "2%" }
                  }
                >
                  {optionalObject?.description || optionName}
                </span>
              ) : showOptionalCode ? (
                <span
                  className="font-bold break-words"
                  style={
                    value > 0
                      ? { color: secondaryColor, padding: "2%" }
                      : { padding: "2%" }
                  }
                >
                  {optionName}
                </span>
              ) : (
                ""
              )}
              {/*{showDescFromBookingText && (*/}
              {/*  <div className="flex flex-col">*/}
              {/*    <div*/}
              {/*      className="font-medium break-words ml-1 text-sm"*/}
              {/*      style={*/}
              {/*        value > 0*/}
              {/*          ? { color: secondaryColor, padding: "2%" }*/}
              {/*          : { padding: "2%" }*/}
              {/*      }*/}
              {/*    >*/}
              {/*      {optionalBody.length > 60*/}
              {/*        ? optionalBody.substring(0, 60) + "..."*/}
              {/*        : optionalBody}*/}
              {/*    </div>*/}
              {/*  </div>*/}
              {/*)}*/}
              {showOptionalDescription && (
                <span
                  className="font-bold break-words"
                  style={
                    value > 0
                      ? { color: secondaryColor, padding: "2%" }
                      : { padding: "2%" }
                  }
                >
                  {details}
                </span>
              )}
              {!showOptionalCode &&
                !showOptionalDescription &&
                !appSettings?.showOptionalCodeFromTextRes && (
                  <span
                    className="font-bold break-words"
                    style={
                      value > 0
                        ? { color: secondaryColor, padding: "2%" }
                        : { padding: "2%" }
                    }
                  >
                    {optionName}
                  </span>
                )}
            </main>
            {showDescFromBookingText && (
              <div style={{ float: "right" }}>
                <Button onClick={toogleVehicleInfoCardShowingStatus}>
                  <p className="text-md capitalize text-black">Info</p>
                </Button>
              </div>
            )}
          </div>
          <div
            className="flex flex-row gap-2 h-full"
            style={
              value > 0
                ? {
                    color: secondaryColor,
                    paddingLeft: "4.5%",
                    paddingRight: "10%",
                    paddingBottom: "5%",
                    marginTop:
                      showDescFromBookingText &&
                      showOptionalDescription &&
                      !showOptionalCode
                        ? "10px"
                        : "30px",
                    justifyContent: "space-between",
                  }
                : {
                    paddingLeft: "4.5%",
                    paddingRight: "10%",
                    paddingBottom: "5%",
                    marginTop:
                      showDescFromBookingText &&
                      showOptionalDescription &&
                      !showOptionalCode
                        ? "10px"
                        : "30px",
                    justifyContent: "space-between",
                  }
            }
          >
            <span className="whitespace-nowrap">
              {defaultCurrency ||
                formatPriceWithCurrencySymbol(BookingGetters.getCurrency())
                  .symbol}
              {"  "}
              {pricePerDayInUSD}
              {/*{isDailyPrice && <span>{translate("/day")}</span>}*/}
            </span>
            <div
              onClick={() => {
                onDecrementClick();
              }}
              className={
                "w-6 h-6 flex items-center text-center justify-center cursor-pointer select-none" +
                " " +
                buttonBorderStyles
              }
            >
              -
            </div>
            <span className="w-6 h-6 flex items-center text-center justify-center black">
              {value}
            </span>
            <div
              onClick={() => {
                onIncrementClick();
              }}
              className={
                "w-6 h-6 flex items-center text-center justify-center  cursor-pointer select-none" +
                " " +
                buttonBorderStyles
              }
              style={{
                backgroundColor: value > 0 ? secondaryColor : "",
                color: value > 0 ? selectedCardTextColor : "",
              }}
            >
              <span className="w-fit h-fit  black  ">+</span>
            </div>
          </div>
        </Card>
      ) : (
        <Card
          className={`shadow-md shadow-sm md-4`}
          sx={{
            maxWidth: 550,
            height: "130px",
            borderRadius: theme.shape.borderRadius,
            boxShadow:
              "0 4px 6px rgba(0, 0, 0, 0.1), 0 2px 6px rgba(0, 0, 0, 0.1)",
            margin: "8px",
          }}
        >
          {showDescFromBookingText && (
            <div style={{ float: "right" }}>
              <Button onClick={toogleVehicleInfoCardShowingStatus}>
                <p className="text-md capitalize text-black">Info</p>
              </Button>
            </div>
          )}
          <div className="w-full h-full">
            <div
              className="flex flex-row items-center text-sm"
              style={{
                marginLeft: "6px",
                height: "55px",
                marginTop: "9px",
              }}
            >
              {iconUrl != "#" && appSettings?.showHideExtrasImages && (
                <img
                  src={iconUrl}
                  className="aspect-square"
                  style={{
                    width: "10%",
                    marginLeft: isDesktopScreen ? "6px" : "2px",
                  }}
                  alt={""}
                ></img>
              )}
              {appSettings?.showOptionalCodeFromTextRes ? (
                <span
                  className="font-bold break-words"
                  style={
                    value > 0
                      ? { color: secondaryColor, padding: "2%" }
                      : { padding: "2%" }
                  }
                >
                  {optionalObject?.description || optionName}
                </span>
              ) : showOptionalCode ? (
                <span
                  className="font-bold break-words"
                  style={
                    value > 0
                      ? { color: secondaryColor, padding: "2%" }
                      : { padding: "2%" }
                  }
                >
                  {optionName}
                </span>
              ) : (
                ""
              )}

              {showOptionalDescription && (
                <span
                  className="font-bold break-words"
                  style={
                    value > 0
                      ? { color: secondaryColor, padding: "2%" }
                      : { padding: "2%" }
                  }
                >
                  {details}
                </span>
              )}
              {!showOptionalCode &&
                !showOptionalDescription &&
                !appSettings?.showOptionalCodeFromTextRes && (
                  <span
                    className="font-bold break-words"
                    style={
                      value > 0
                        ? { color: secondaryColor, padding: "2%" }
                        : { padding: "2%" }
                    }
                  >
                    {optionName}
                  </span>
                )}
            </div>
            <div
              className="flex flex-row"
              style={{
                justifyContent: "space-between",
                marginTop: !isSmallScreen ? marginTopStyle : "0px",
                marginLeft: isDesktopScreen ? "3px" : "undefined",
              }}
            >
              <span
                className="whitespace-nowrap"
                style={
                  {
                    color: "black",
                    fontWeight: "normal",
                    marginLeft: !isSmallScreen ? "5px" : "0px",
                    marginTop: marginTopStyle1,
                  } && value > 0
                    ? {
                        color: secondaryColor,
                        marginLeft: "8px",
                        marginTop: "15px",
                      }
                    : {
                        marginLeft: isDesktopScreen ? "8px" : "10px",
                        marginTop: isDesktopScreen ? "15px" : "17px",
                      }
                }
              >
                {defaultCurrency ||
                  formatPriceWithCurrencySymbol(BookingGetters.getCurrency())
                    .symbol}
                {"  "}
                <span>{pricePerDayInUSD}</span>
                {/*{isDailyPrice && <span>{translate("/day")}</span>}*/}
              </span>
              <div
                style={{
                  color: value > 0 ? selectedCardTextColor : "",
                  height: "100px",
                  marginRight: !isSmallScreen ? "18px" : "8px",
                  marginBottom: "5px",
                  marginTop: isDesktopScreen ? "0px" : marginTopStyle,
                }}
              >
                {value > 0 ? (
                  <>
                    <div className="flex flex-row">
                      <CheckCircleOutlined
                        style={{
                          color: secondaryColor,
                          marginTop: marginTopStyle,
                        }}
                      />
                      <AppButton
                        color={value > 0 ? "secondaryColor" : "primary"}
                        onClick={() => {
                          onDecrementClick();
                        }}
                        className="w-[5rem] h-[2rem] rounded-md ml-[15px]"
                        style={{ marginTop: marginTopStyle }}
                      >
                        <p
                          className={`min-w-24 w-full h-full flex items-center justify-center capitalize font-normal`}
                          style={{
                            fontSize: "x-small",
                            fontFamily: fontFamilyStyle
                              ? fontFamilyStyle
                              : undefined,
                          }}
                        >
                          {extrasRemoveButtonLabel ? (
                            <div
                              dangerouslySetInnerHTML={{
                                __html: extrasRemoveButtonLabel,
                              }}
                            />
                          ) : (
                            <span>{translate("Remove")}</span>
                          )}
                        </p>
                      </AppButton>
                    </div>
                  </>
                ) : (
                  <AppButton
                    color={value > 0 ? "secondaryColor" : "primary"}
                    onClick={() => {
                      onIncrementClick();
                    }}
                    className="w-[5rem] h-[2rem] rounded-md ml-[28px]"
                    style={{ marginTop: marginTopStyle }}
                  >
                    <p
                      className={`min-w-24 w-full h-full flex items-center justify-center capitalize font-normal`}
                      style={{
                        fontSize: "x-small",
                        fontFamily: fontFamilyStyle,
                      }}
                    >
                      {extrasSelectButtonLabel ? (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: extrasSelectButtonLabel,
                          }}
                        />
                      ) : (
                        <span>{translate("Select")}</span>
                      )}
                    </p>
                  </AppButton>
                )}
              </div>
            </div>
          </div>
        </Card>
      )}

      {showInfo && (
        <div
          style={{ overflowY: "auto" }}
          className="hover:drop-shadow-lg cursor-pointer rounded-tl-[32px] rounded-br-[12px] rounded-bl-[12px] w-full absolute bg-white p-4 z-10 top-0 left-0 animate__animated animate__zoomIn"
        >
          <div
            className={"flex flex-row"}
            style={{ justifyContent: "space-between" }}
          >
            <div className={"text-black text-sm"}>{optionalBody}</div>
            <Button
              className="w-1/3 flex justify-end"
              onClick={() => {
                toogleVehicleInfoCardShowingStatus();
              }}
            >
              {translate("Close")}
            </Button>
          </div>
        </div>
      )}
    </>
  );
}
export { VehicleExtrasWithInfo as default };
