import React, { ChangeEvent, ForwardedRef, forwardRef } from "react";

import { AppMobileNumberProps } from "./AppMobileNumberInput.props";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import { useTranslation } from "react-i18next";
import BookingGetters from "../../../store/onlineBooking/getters";
const MobileNumberInputFieldWithFlag = forwardRef(
  function MobileNumberInputFieldWithFlag(
    props: AppMobileNumberProps,
    ref: ForwardedRef<HTMLInputElement>
  ): JSX.Element {
    const { t } = useTranslation();
    const appSettings = BookingGetters.getAppSettings();
    const fontFamilyStyle = appSettings?.fontFamily;
    const isDesktopScreen = window.innerWidth >= 1310;
    return (
      <div
        className={
          "w-full h-full box-border items-baseline flex flex-col justify-end " +
          props.className
        }
        style={{
          paddingBottom: "1.3rem",
          paddingTop: "0.5rem",
        }}
      >
        <PhoneInput
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          ref={(e) => {
            return (ref = e);
          }}
          specialLabel={
            <span style={{ fontFamily: fontFamilyStyle }}>
              {t("Phone")}
              {props.required && <span style={{ color: "red" }}> *</span>}
            </span>
          }
          containerClass={" w-full text-black"}
          country="it"
          inputClass={"w-full h-full text-black"}
          dropdownClass={"mx-4"}
          placeholder={props.placeholder}
          inputProps={{ required: props.required }}
          value={props.value}
          inputStyle={{
            height: "100%",
            width: "100%",
            color: "black",
            fontFamily: fontFamilyStyle,
          }}
          onChange={(phone) => {
            props.onChange(phone, {} as ChangeEvent<HTMLInputElement>);
          }}
        />
      </div>
    );
  }
);
export { MobileNumberInputFieldWithFlag };
