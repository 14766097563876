import React, { forwardRef } from "react";

import { AppTimePickerProps } from "./AppTimeSelect.d";
import SlotTimePicker from "react-datepicker";
import { TextField } from "@mui/material";
import { TimePicker } from "@mui/x-date-pickers";
import BookingGetters from "../../../store/onlineBooking/getters";

export const AppTimePickerMaterial = forwardRef(AppTimePickerMaterialComponent);
export function AppTimePickerMaterialComponent(
  { className, ...props }: AppTimePickerProps,
  ref
) {
  return (
    <div className={"w-full " + className}>
      <TimePicker
        {...props}
        renderInput={(params) => <TextField {...params} fullWidth />}
        inputRef={ref}
      />
    </div>
  );
}

export const AppTimePickerStandard = forwardRef(AppTimePickerStandardComponent);
export function AppTimePickerStandardComponent(
  {
    className,
    onChange = () => {},
    enableTimesInRange = [],

    ...props
  }: AppTimePickerProps,
  ref
) {
  const filterTime = (time) => {
    return enableTimesInRange.some((range) => {
      const startTime = range.start.getTime();
      const endTime = range.end.getTime();
      const currentTime = time.getTime();
      return currentTime >= startTime && currentTime <= endTime;
    });
  };
  const appSettings = BookingGetters.getAppSettings();
  const fontFamilyStyle = appSettings?.fontFamily;
  return (
    <div className={"w-full " + className}>
      <SlotTimePicker
        {...props}
        onChange={(e) => {
          onChange(e);
        }}
        selected={props.value}
        showTimeSelect
        showTimeSelectOnly
        timeIntervals={30}
        dateFormat="HH:mm"
        timeFormat="HH:mm"
        filterTime={filterTime}
        customInput={
          <TextField
            inputRef={ref}
            InputProps={{ readOnly: true }}
            label={
              <span style={{ fontFamily: fontFamilyStyle }}>{props.label}</span>
            }
            required={props.required}
            fullWidth
            sx={{
              width: "100%",
              "@media (max-width: 768px)": {
                width: "100%",
              },
              "& .MuiInputBase-input": {
                fontFamily: fontFamilyStyle,
              },
            }}
          />
        }
        timeCaption={props.label}
      />
    </div>
  );
}
